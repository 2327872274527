import React, { Component, createRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Constante from "../Util/Constante";
import Util from "../Util/Util";
import HeaderAdm from "./Components/HeaderAdm";
import { Navigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import { InputNumber } from "primereact/inputnumber";
import { PedidoItemOgt } from "../Service/PedidoItemOgt";
import { PedidoOgt } from "../Service/PedidoOgt";
import { OgtService } from "../Service/OgtService";

export default class EmpresaCartao extends Component {
  toast = createRef();
  constante = new Constante();
  mensagem = createRef();
  mensagemCorrecao = createRef();
  util = new Util();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
  ogtService = new OgtService(this.constante.url_base_ogt);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaEmpresa: new Pessoa(),
    pessoaOperadora: new Pessoa(),
    pessoaFuncionario: new Pessoa(),
    listaCartaoSelecionado: [],
    //Modal
    vModalNovo: false,
    vModalRelatorio: false,
    vModaExcel: false,
    buscarCarregando: false,
    salvarCarregando: false,
    tabelaCarregando: false,
    arquivoExcel: null,

    liberarAcesso: {
      detalheCartao: false,
    },

    listaEmpresa: [],
    listaCartao: [],
    listaFuncionario: [],
    listaOperadora: [],

    indexTab: 0,

    inputError: {
      nome: false,
      cpf: false,
      matricula: false,
      numeroCartao: false,
      empresa: false,
      arquivo: false,
      operadora: false,
      loginOperadora: false,
      senhaOperadora: false,
      diasTrabalhado: false,
      valorDiario: false,
    },

    inputErrorFiltro: {
      empresa: false
    },


    filtros: {
      nome: '',
      dataCadastro_inicial: '',
      dataCadastro_final: '',
      seqPerfil: '',
      documento: '',
      pessoaEmpresa: null,
    }

  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    // let listaCartao = await this.listar(pessoaUsuario);
    this.listarEmpresa();
    this.listarOperadora();
    this.setState({
      pessoaUsuario: pessoaUsuario,
      // listaCartao: listaCartao,
    })
  }

  async salvar() {
    this.setState({ salvarCarregando: true })
    this.mensagem.current.clear();
    let pessoaFuncionario = this.state.pessoaFuncionario;
    pessoaFuncionario.seqUsuario = this.constante.seqUsuario;
    pessoaFuncionario.seqPessoaPerfil = this.constante.seqPerfilFuncionarioOK;
    pessoaFuncionario.tipoDocumento = "CPF";
    pessoaFuncionario.situacao = "ATIVO";
    pessoaFuncionario.tags2 = this.state.pessoaOperadora.nome
    pessoaFuncionario.tagn3 = this.state.pessoaOperadora.tagn3
    let retorno = await this.pessoaService.salvar(pessoaFuncionario);
    pessoaFuncionario = retorno.objeto;

    let pessoaRelacionamento = new PessoaRelacionamento();
    pessoaRelacionamento.seqUsuario = this.constante.seqUsuario;
    pessoaRelacionamento.seqPessoa = pessoaFuncionario._seqPessoa;
    pessoaRelacionamento.seqPessoaRelacionada = this.state.pessoaEmpresa._seqPessoa;
    pessoaRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoEmpresa;
    retorno = await this.pessoaService.salvarRelacionamento(pessoaRelacionamento);
    console.log(retorno);

    this.listar()

    this.resetar();
    this.mensagem.current.show([
      {
        severity: "success",
        summary: "Cartão cadastrado com sucesso.",
        sticky: true,
      },
    ]);
  }

  resetar() {
    let inputError = this.state.inputError;
    let listaKey = Object.keys(inputError);
    listaKey.forEach(key => inputError[key] = false);
    this.setState({
      vModalNovo: false,
      inputError: inputError,
      salvarCarregando: false,
      arquivoExcel: null,
      pessoaFuncionario: new Pessoa(),
      pessoaEmpresa: new Pessoa(),
      pessoaOperadora: new Pessoa(),
    })
  }

  async listar(pPessoaUsuario) {
    this.setState({ tabelaCarregando: true });
    let pessoaUsuario = this.state.pessoaUsuario;
    if (pPessoaUsuario) {
      pessoaUsuario = pPessoaUsuario;
    }
    const listaPesquisa = [];

    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoEmpresa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);


    if (this.state.filtros.seqPerfil) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.seq_pessoa_perfil";
      pesquisa.conteudo = this.state.filtros.seqPerfil;
      pesquisa.operacao = "AND";
      pesquisa.tipo = "TEXTO";
      listaPesquisa.push(pesquisa);
    }


    if (this.state.filtros.nome) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.nome";
      pesquisa.conteudo = this.state.filtros.nome;
      pesquisa.operacao = "AND";
      pesquisa.tipo = "TEXTO";
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtros.documento) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.documento";
      pesquisa.conteudo = this.state.filtros.documento;
      pesquisa.operacao = "AND";
      pesquisa.tipo = "TEXTO";
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtros?.pessoaEmpresa?._seqPessoa) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa_relacionamento.seq_pessoa_relacionada';
      pesquisa.conteudo = this.state.filtros.pessoaEmpresa._seqPessoa;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtros.dataCadastro_inicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.data_cadastro";
      pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtros.dataCadastro_inicial);
      pesquisa.operacao = "AND";
      pesquisa.tipo = "DATA_INICIAL";
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtros.dataCadastro_final) {
      pesquisa = new Pesquisa();
      pesquisa.campo = "pessoa.data_cadastro";
      pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtros.dataCadastro_final);
      pesquisa.operacao = "AND";
      pesquisa.tipo = "DATA_FINAL";
      listaPesquisa.push(pesquisa);
    }

    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    let listaCartao = retorno.objeto;

    console.log(listaPesquisa);
    console.log(listaCartao);

    this.setState({
      listaCartao: listaCartao,
      tabelaCarregando: false,
    })
    return listaCartao;
  }

  async listarEmpresa() {
    this.listaPesquisa = [

      this.util.criarObjetoPesquisa(
        "pessoa.seq_usuario",
        this.constante.seqUsuario,
        "",
        "TEXTO"
      ),
      this.util.criarObjetoPesquisa(
        "pessoa.seq_pessoa_perfil",
        this.constante.seqPerfilEmpresa,
        "AND",
        "TEXTO"
      ),

    ]
    this.retornoListaPequisa = await this.pessoaService.listarComFiltro(this.listaPesquisa)
    this.listaEmpresa = this.retornoListaPequisa.objeto


    this.setState({
      listaEmpresa: this.listaEmpresa,
    })

  }

  async listarOperadora() {
    let listaPesquisa = [

      this.util.criarObjetoPesquisa(
        "pessoa.seq_usuario",
        this.constante.seqUsuario,
        "",
        "TEXTO"
      ),
      this.util.criarObjetoPesquisa(
        "pessoa.seq_pessoa_perfil",
        this.constante.seqPerfilOperadora,
        "AND",
        "TEXTO"
      ),

    ]

    this.retornoListaPequisa = await this.pessoaService.listarComFiltro(listaPesquisa)
    let listaOperadora = this.retornoListaPequisa.objeto
    console.log("lista operadora", listaOperadora);

    let pessoaFuncionario = this.state.pessoaFuncionario
    let pessoaOperadora = new Pessoa()

    for (let i = 0; i < listaOperadora.length; i++) {
      if (listaOperadora[i].tagn3 === pessoaFuncionario.tagn3) {
        pessoaOperadora = listaOperadora[i]
      }
    }

    this.setState({
      listaOperadora: listaOperadora,
      pessoaOperadora: pessoaOperadora
    })

  }

  validarInputs() {
    let inputError = this.state.inputError;
    console.log(inputError);
    let listaKey = Object.keys(inputError);
    let inputOk = true;
    listaKey.forEach(key => inputError[key] = false);

    if (!this.state.pessoaFuncionario.nome) {
      inputError.nome = true
    }

    if (!this.state.pessoaFuncionario.documento) {
      inputError.cpf = true
    }

    if (this.state.pessoaFuncionario.documento && this.state.pessoaFuncionario.documento < 14) {
      inputError.cpf = true
    }

    if (!this.state.pessoaFuncionario.tags0) {
      inputError.matricula = true;
    }


    if (!this.state.pessoaFuncionario.tags1) {
      inputError.numeroCartao = true;
    }


    if (!this.state.pessoaOperadora._seqPessoa) {
      inputError.operadora = true;
    }

    if (!this.state.pessoaFuncionario.tagn0) {
      inputError.diasTrabalhado = true;
    }

    if (!this.state.pessoaFuncionario.tagn1) {
      inputError.valorDiario = true;
    }

    if (!this.state.pessoaEmpresa._seqPessoa) {
      inputError.empresa = true;
    }

    listaKey.forEach(key => {
      if (inputError[key]) {
        inputOk = false
      }
    })

    this.setState({ inputError: inputError })
    return inputOk
  }


  limparInputsError() {
    let inputError = this.state.inputError;
    let listaKey = Object.keys(inputError);
    listaKey.forEach(key => {
      inputError[key] = false;
    });
    this.setState({ inputError: inputError });
  }

  ValidarInputFiltro() {
    let inputErrorFiltro = this.state.inputErrorFiltro;
    console.log(inputErrorFiltro);
    let listaKey = Object.keys(inputErrorFiltro);
    let inputOk = true;
    listaKey.forEach(key => inputErrorFiltro[key] = false);


    if (!this.state.filtros.pessoaEmpresa) {
      inputErrorFiltro.empresa = true;
    }

    listaKey.forEach(key => {
      if (inputErrorFiltro[key]) {
        inputOk = false
      }
    })

    this.setState({ inputErrorFiltro: inputErrorFiltro })
    return inputOk

  }

  gerenciarArquivoExcel(pArquivo) {
    let inputError = this.state.inputError;
    if (!pArquivo) {
      inputError.arquivo = true;
      this.setState({ inputError: inputError });
      return;
    }
    if (!this.state.pessoaEmpresa._seqPessoa) {
      inputError.empresa = true;
      this.setState({ inputError: inputError });
      return
    };

    const fileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    if (pArquivo) {
      if (pArquivo && fileType.includes(pArquivo.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(pArquivo);
        reader.onload = (e) => {
          this.toast.current.clear();
          this.importarListaExcel(e.target.result);
        }
      }
      else {
        this.toast.current.show([
          { severity: 'error', summary: 'Por favor, selecione um arquivo Excel!', sticky: true },
        ]);
        this.setState({
          arquivoExcel: null
        })
      }
    }
  }

  async importarListaExcel(pArquivoExcel) {
    this.mensagem.current.clear();
    const workbook = XLSX.read(pArquivoExcel, { type: 'buffer' });
    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];
    const listaFuncionarioExcel = XLSX.utils.sheet_to_json(worksheet);
    const listaFuncionario = [];

    for (let i = 0; i < listaFuncionarioExcel.length; i++) {
      let pessoa = new Pessoa();
      pessoa.nome = listaFuncionarioExcel[i]?.NomeFuncionario?.toString();
      pessoa.seqUsuario = this.constante.seqUsuario;
      pessoa.documento = listaFuncionarioExcel[i]?.CPF?.toString();
      pessoa.tags0 = listaFuncionarioExcel[i]?.Matricula?.toString();
      pessoa.tags1 = listaFuncionarioExcel[i]?.NumeroCartao?.toString();
      pessoa.tags2 = this.state.pessoaEmpresa.nome;
      pessoa.tags3 = listaFuncionarioExcel[i]?.Operadora?.toString();
      pessoa.tags4 = listaFuncionarioExcel[i]?.LoginOperadora?.toString();
      pessoa.tags5 = listaFuncionarioExcel[i]?.SenhaOperadora?.toString();
      pessoa.tagn0 = listaFuncionarioExcel[i]?.diasTrabalhado?.toString();
      pessoa.seqPessoaPerfil = this.constante.seqPerfilFuncionarioOK;
      let retorno = await this.pessoaService.salvar(pessoa);
      pessoa = retorno.objeto;

      retorno = await this.util.inconsistencia(pessoa);
      if (retorno) {
        pessoa.seqPessoaPerfil = this.constante.seqPerfilFuncionarioPendente;
        let pessoaRetorno = await this.pessoaService.salvar(pessoa);
        pessoa = pessoaRetorno.objeto;
        console.log('FOI SALVO O INCOSICSNTENAIJ', pessoa)
      }

      let relacionamento = new PessoaRelacionamento();
      relacionamento.seqUsuario = this.constante.seqUsuario;
      relacionamento.seqPessoa = pessoa._seqPessoa;
      relacionamento.seqPessoaRelacionada = this.state.pessoaEmpresa._seqPessoa;
      relacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoEmpresa;
      this.pessoaService.salvarRelacionamento(relacionamento);
      listaFuncionario.push(pessoa)
    }

    this.setState({
      listaFuncionario: listaFuncionario,
      vModalRelatorio: true
    })

    this.listar();
    this.resetar();
  }


  selecionar(pPessoaFuncionario) {
    let pessoaFuncionario = pPessoaFuncionario
    console.log('funcionario selecionado', pessoaFuncionario);
    this.setState({ pessoaFuncionario: pessoaFuncionario })
    this.navegar('detalheCartao')
  }

  selecionarLote(pItem) {
    this.setState({ listaCartaoSelecionado: pItem });
  }


  async buscarSaldo(pItem) {
    this.setState({ tabelaCarregando: true })
    let pessoaFuncionario = pItem;
    console.log("Cartão selecionado", pessoaFuncionario)
    let retorno = await this.pessoaService.buscarPorSeqPessoa(pessoaFuncionario._seqPessoaRelacionada);
    let pessoaEmpresa = retorno.objeto;

    let retornoOgt = await this.ogtService.validarAcessoOgt(this.constante.cpfOgt, this.constante.senhaOGt);
    let token = retornoOgt?.data?.accessToken;
    console.log("TOKEN", token);

    let listaItem = [];
    let pedidoItemOgt = new PedidoItemOgt();
    pedidoItemOgt.nomeFuncionario = pessoaFuncionario.nome;
    pedidoItemOgt.cpf = pessoaFuncionario.documento;
    pedidoItemOgt.numeroCartao = pessoaFuncionario.tags1;
    pedidoItemOgt.idOperadora = pessoaFuncionario.tagn3;
    pedidoItemOgt.loginOperadora = pessoaEmpresa.tags3;
    pedidoItemOgt.senhaOperadora = pessoaEmpresa.tags4;
    pedidoItemOgt.cdempresa = this.constante.cdEmpresa;
    console.log('Pedido Item', pedidoItemOgt);
    listaItem.push(pedidoItemOgt);

    let pedidoOgt = new PedidoOgt();
    pedidoOgt.idArquivo = this.constante.idArquivo;
    pedidoOgt.pedidoItens = listaItem;
    pedidoOgt.idPedido = Math.floor(Math.random() * (100 - 1 + 1));
    pedidoOgt.nrSolicitacao = 0;
    console.log('Pedido', pedidoOgt);

    retornoOgt = await this.ogtService.enviarLote(pedidoOgt, token);
    console.log("lote", retornoOgt);
    if (retornoOgt?.errors) {
      this.setState({ tabelaCarregando: false })
      return
    }

    pessoaFuncionario.tagn4 = pedidoOgt.idPedido;
    pessoaFuncionario.tags9 = '2';
    console.log("Funcionario sendo salvo", pessoaFuncionario);
    retorno = await this.pessoaService.salvar(pessoaFuncionario);
    console.log('SALVO', retorno.objeto);
    this.listar();
    this.setState({ tabelaCarregando: false })
  }

  async buscarSaldoEmLote() {
    this.setState({ buscarCarregando: true })
    let listaCartaoSelecionado = this.state.listaCartaoSelecionado;
    if (!listaCartaoSelecionado.length) {
      this.setState({ buscarCarregando: false })
      return
    }
    const pessoaEmpresa = this.state.filtros.pessoaEmpresa;
    const listaItem = [];
    let idPedido = Math.floor(Math.random() * (100 - 1 + 1));
    let retornoOgt = await this.ogtService.validarAcessoOgt(this.constante.cpfOgt, this.constante.senhaOGt);
    let token = retornoOgt?.data?.accessToken;
    console.log("TOKEN", token);

    listaCartaoSelecionado.forEach(async cartao => {
      let pedidoItemOgt = new PedidoItemOgt();
      pedidoItemOgt.nomeFuncionario = cartao.nome;
      pedidoItemOgt.cpf = cartao.documento;
      pedidoItemOgt.numeroCartao = cartao.tags1;
      pedidoItemOgt.idOperadora = cartao.tagn3;
      pedidoItemOgt.loginOperadora = pessoaEmpresa.tags3;
      pedidoItemOgt.senhaOperadora = pessoaEmpresa.tags4;
      pedidoItemOgt.cdempresa = this.constante.cdEmpresa;
      listaItem.push(pedidoItemOgt);
      cartao.tagn4 = idPedido;
      cartao.tags9 = '2';
      let retorno = await this.pessoaService.salvar(cartao);
      console.log("PESSOA SALVA", retorno.objeto)
    })

    let pedidoOgt = new PedidoOgt();
    pedidoOgt.idArquivo = this.constante.idArquivo;
    pedidoOgt.pedidoItens = listaItem;
    pedidoOgt.idPedido = idPedido;
    pedidoOgt.nrSolicitacao = 0;
    console.log('Pedido', pedidoOgt);
    retornoOgt = await this.ogtService.enviarLote(pedidoOgt, token);
    console.log("lote", retornoOgt);
    if (retornoOgt?.errors) {
      for (let i = 0; i < listaCartaoSelecionado.length; i++) {
        listaCartaoSelecionado[i].tags9 = '1';
        let retorno = await this.pessoaService.salvar(listaCartaoSelecionado[i]);
        console.log(retorno)
      }
    }
    this.listar();
    this.setState({ buscarCarregando: false })
  }
  async AtualizarSaldo(pItem) {
    this.setState({ tabelaCarregando: true });
    let pessoaFuncionario = pItem;
    console.log("Cartão selecionado", pessoaFuncionario)
    let retornoOgt = await this.ogtService.validarAcessoOgt(this.constante.cpfOgt, this.constante.senhaOGt);
    let token = retornoOgt?.data?.accessToken;
    console.log("TOKEN", token);
    retornoOgt = await this.ogtService.obterResultado(pessoaFuncionario.tagn4, 0, token);
    console.log('retorno ogt', retornoOgt);
    if (!retornoOgt.data) {
      this.setState({ tabelaCarregando: false })
      return
    }

    pessoaFuncionario.tagn2 = retornoOgt.data[0].saldo;
    pessoaFuncionario.tagd0 = this.util.converterDataRegex(retornoOgt.data[0].dataUltimaAtualizacao);
    pessoaFuncionario.tags9 = '1';
    let retornoPessoa = await this.pessoaService.salvar(pessoaFuncionario);
    pessoaFuncionario = retornoPessoa.objeto;
    console.log('atualizado', pessoaFuncionario)

    this.listar();
    this.setState({ tabelaCarregando: false });
  }

  async atualizarSaldoEmLote() {
    this.setState({ buscarCarregando: true })
    let listaCartao = this.state.listaCartao;
    const listaComNumeroPedido = [];
    this.toast.current.clear();

    listaCartao.forEach(cartao => {
      if (cartao.tags9 === '2') {
        listaComNumeroPedido.push(cartao.tagn4);
      }
    })

    if (!listaComNumeroPedido.length) {
      this.setState({ buscarCarregando: false })
      return
    }

    let retornoOgt = await this.ogtService.validarAcessoOgt(this.constante.cpfOgt, this.constante.senhaOGt);
    let token = retornoOgt?.data?.accessToken;
    console.log("TOKEN", token);

    let listaNumeroPedidoSemDuplicidade = [...new Set(listaComNumeroPedido)];

    listaNumeroPedidoSemDuplicidade.forEach(async numeroPedido => {
      retornoOgt = await this.ogtService.obterResultado(numeroPedido, 0, token);
      if (!retornoOgt.data) {
        this.setState({ buscarCarregando: false })
        return
      }
      let listaOgt = retornoOgt.data;
      for (let i = 0; i < listaCartao.length; i++) {
        for (let j = 0; j < listaOgt.length; j++) {
          if (listaOgt[j].numeroCartao === this.util.removerCaracter(listaCartao[i].tags1)) {
            listaCartao[i].tagn2 = listaOgt[j].saldo;
            listaCartao[i].tagd0 = this.util.converterDataRegex(listaOgt[j].dataUltimaAtualizacao);
            listaCartao[i].tags9 = '1';
            let retorno = await this.pessoaService.salvar(listaCartao[i]);
            console.log('PESSOA SALVA', retorno)
          }
        }
      }
    }
    )
    this.setState({ buscarCarregando: false });
    this.listar();
  }


  navegar(pTela) {
    const liberarAcesso = this.state.liberarAcesso;
    liberarAcesso[pTela] = true;
    this.setState({ liberarAcesso: liberarAcesso });
  }


  render() {
    return (
      <div>
        <Toast ref={this.toast} />
        {/* NAVEGAÇÃO */}
        {this.state.liberarAcesso.detalheCartao && <Navigate to={"/sistema/admin/detalhe_cartao?id=" + (this.state.pessoaFuncionario._seqPessoa)} />}

        {/* CADASTRO DE CARTOES */}
        <Dialog Dialog
          header="Cadastro de cartões"
          visible={this.state.vModalNovo}
          style={{ width: 900 }}
          onHide={() => this.resetar()}
        >

          <form
            onSubmit={async (e) => {
              e.preventDefault();
              let inputsOkay = this.validarInputs();
              if (!inputsOkay) {
                return;
              } else {
                this.salvar();
              }
            }}
            style={{
              padding: 5,
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <div
              style={{
                marginTop: 5,
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: 20,
              }}
            >

              <div>
                <label>Empresa</label><br />
                <Dropdown
                  onChange={pItem => this.setState({ pessoaEmpresa: pItem.target.value })}
                  value={this.state.pessoaEmpresa}
                  options={this.state.listaEmpresa}
                  optionLabel='nome'
                  placeholder='Selecione a Empresa'
                  filter
                  filterBy="nome"
                  style={{
                    width: "100%",
                    height: 40,
                    borderColor: this.state.inputError.empresa && "var(--red-600)",
                  }}
                />
                {this.state.inputError.empresa && (
                  <small style={{ color: "var(--red-600)" }}>
                    Selecione uma empresa
                  </small>
                )}
              </div>

              <div>
                <label>Nome</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, nome: pTexto.target.value }
                  })}
                  value={this.state.pessoaFuncionario.nome || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.nome && "var(--red-600)" }} />
                {this.state.inputError.nome && (
                  <small style={{ color: "var(--red-600)" }}>
                    Nome inválido
                  </small>
                )}
              </div>

              <div>
                <label>CPF</label><br />
                <InputText
                  type="tel"
                  value={this.state.pessoaFuncionario.documento || ''}
                  onChange={e => {
                    if (e.target.value.length > 14) return;
                    this.setState({
                      pessoaFuncionario: {
                        ...this.state.pessoaFuncionario,
                        documento: this.util.formatarCPF(e.target.value)
                      }
                    })
                  }}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.cpf && "var(--red-600)" }} />
                {this.state.inputError.cpf && (
                  <small style={{ color: "var(--red-600)" }}>
                    Cpf inválido
                  </small>
                )}
              </div>
              <div>
                <label>Matricula</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, tags0: pTexto.target.value }
                  })}
                  value={this.state.pessoaFuncionario.tags0 || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.matricula && "var(--red-600)" }} />
                {this.state.inputError.matricula && (
                  <small style={{ color: "var(--red-600)" }}>
                    Matricula inválido
                  </small>
                )}
              </div>

              <div>
                <label>N° do cartão </label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, tags1: pTexto.target.value }
                  })}
                  value={this.state.pessoaFuncionario.tags1 || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.numeroCartao && "var(--red-600)" }} />
                {this.state.inputError.numeroCartao && (
                  <small style={{ color: "var(--red-600)" }}>
                    Numero cartão inválido
                  </small>
                )}
              </div>

              <div>
                <label>Dias trabalhados</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, tagn0: pTexto.target.value }
                  })}
                  value={this.state.pessoaFuncionario.tagn0 || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.diasTrabalhado && "var(--red-600)" }} />
                {this.state.inputError.diasTrabalhado && (
                  <small style={{ color: "var(--red-600)" }}>
                    Dias trabalhados inválido
                  </small>
                )}
              </div>

              <div>
                <label>Valor diário</label><br />
                <InputNumber
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, tagn1: pTexto.value }
                  })}
                  value={this.state.pessoaFuncionario.tagn1 || ''}
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  style={{ width: '100%', height: 40 }}
                  inputStyle={{ width: '100%', height: 40, borderColor: this.state.inputError.valorDiario && "var(--red-600)" }} /><br />
                {this.state.inputError.valorDiario && (
                  <small style={{ color: "var(--red-600)" }}>
                    Valor diário inválido
                  </small>
                )}
              </div>

              <div>
                <label>Operadora</label><br />
                <Dropdown
                  onChange={pItem => this.setState({ pessoaOperadora: pItem.value })}
                  value={this.state.pessoaOperadora}
                  options={this.state.listaOperadora}
                  optionLabel='nome'
                  placeholder='Selecione a operadora'
                  filter
                  filterBy="nome"
                  style={{
                    width: "100%",
                    height: 40,
                    borderColor: this.state.inputError.operadora && "var(--red-600)",
                  }}
                />
                {this.state.inputError.operadora && (
                  <small style={{ color: "var(--red-600)" }}>
                    Selecione uma operadora
                  </small>
                )}
              </div>

            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                loading={this.state.salvarCarregando}
                label="Salvar"
                className="p-button-raised"
                type="submit"
              />
            </div>
          </form>

        </Dialog>

        <Dialog
          header="Importe de excel"
          visible={this.state.vModaExcel}
          style={{ width: 900 }}
          onHide={() => this.setState({ vModaExcel: false })}>
          <h1 style={{ textAlign: 'center' }}>Em desenvolvimento</h1>
        </Dialog>

        <HeaderAdm />
        <main style={{ width: "98%", margin: "20px auto" }}>
          <Messages ref={this.mensagem} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid lightgray",
              padding: 20,
              borderRadius: 3,
            }}
          >
            <div>
              <h1 style={{ fontSize: 22 }}>Cartões</h1>
              <h2 style={{ fontSize: 20, color: 'gray' }}>Cartões</h2>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20 }}>
              <Button
                label="Importar excel"
                className="p-button-raised"
                onClick={() => this.setState({ vModaExcel: true })} />
              <Button
                label="Novo"
                className="p-button-raised"
                onClick={() => this.setState({ vModalNovo: true })} />
            </div>
          </div>

          <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>

              {/* FILTROS */}

              <div>
                <label>Empresa</label><br />
                <Dropdown
                  onChange={pItem => this.setState({
                    filtros: {
                      ...this.state.filtros, pessoaEmpresa: pItem.target.value
                    }
                  })}
                  options={this.state.listaEmpresa}
                  optionLabel='nome'
                  value={this.state.filtros?.pessoaEmpresa}
                  placeholder='Selecione a empresa'
                  style={{
                    width: "100%",
                    height: 40,
                    borderColor: this.state.inputErrorFiltro.empresa && "var(--red-600)"
                  }}
                />
                {this.state.inputErrorFiltro.empresa && (
                  <small style={{ color: "var(--red-600)" }}>
                    Selecione uma empresa
                  </small>
                )}
              </div>


              <div>
                <label>Nome</label><br />
                <InputText
                  onChange={pTexto => this.setState({ filtros: { ...this.state.filtros, nome: pTexto.target.value } })}
                  value={this.state.filtros.nome || ''}
                  placeholder="Nome"
                  style={{ width: '100%', height: 40 }}
                />
              </div>

              <div>
                <label>CPF</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    filtros: {
                      ...this.state.filtros, documento: this.util.formatarCPF(pTexto.target.value)
                    }
                  })}
                  placeholder="000.000.000-00"
                  value={this.state.filtros.documento || ''}
                  style={{ width: '100%', height: 40 }}
                />
              </div>

              <div>
                <label>Data Cadastro Inicial</label><br />
                <Calendar
                  onChange={(pTexto) =>
                    this.setState({
                      filtros: {
                        ...this.state.filtros,
                        dataCadastro_inicial: pTexto.target.value,
                      },
                    })
                  }
                  showButtonBar
                  style={{ width: "100%", height: 40 }}
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                />
              </div>

              <div>
                <label>Data Cadastro Final</label><br />
                <Calendar
                  onChange={(pTexto) =>
                    this.setState({
                      filtros: {
                        ...this.state.filtros,
                        dataCadastro_final: pTexto.target.value,
                      },
                    })
                  }
                  showButtonBar
                  style={{ width: "100%", height: 40 }}
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                />
              </div>


            </div>
            <div style={{ display: 'flex', width: "100%", marginTop: 20, justifyContent: "flex-end" }}>
              <Button loading={this.state.salvarCarregando} label="Buscar" className="p-button-raised"
                onClick={() => {
                  let inputsOkay = this.ValidarInputFiltro();
                  if (!inputsOkay) {
                    return;
                  } else {
                    this.listar();
                  }
                }} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gridTemplateColumns: "1fr",
              marginTop: 20,
              gap: 10,
            }}
          >
            <DataTable
              selectionMode="single"
              showGridlines
              removableSort
              paginator
              responsiveLayout="scroll"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
              style={{ width: "100%" }}
              value={this.state.listaCartao}
              selection={this.state.listaCartaoSelecionado}
              onSelectionChange={e => this.selecionarLote(e.value)}
              loading={this.state.tabelaCarregando}
              header={() => <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 >Cartões</h2>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 20 }}>
                  <Button
                    label="Atualizar saldo"
                    loading={this.state.buscarCarregando}
                    onClick={() => this.atualizarSaldoEmLote()}
                  />

                  <Button
                    label="Buscar saldo"
                    loading={this.state.buscarCarregando}
                    onClick={() => this.buscarSaldoEmLote()} />
                </div>
              </div>}
            >
              <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
              <Column header='Empresa' field='_pessoaRelacionadaNome' sortable />
              <Column header='Nome' field='nome' sortable />
              <Column header='CPF' field='documento' sortable />
              <Column header='Matricula' field='tags0' sortable />
              <Column header='N. Cartão' field='tags1' sortable />
              <Column header='Operadora' field='tags2' sortable />
              <Column header='Dias trabalhados' field='tagn0' sortable />
              <Column header='Valor diario' field='tagn1' sortable body={item => <p>{this.util.formatarValor(item.tagn1)}</p>} />
              <Column header='Saldo' field='tagn2' sortable body={item => <p>{this.util.formatarValor(item.tagn2)}</p>} />
              <Column header='Data atualização' sortable body={item => <p>{this.util.formatarDataComTimestamp(item.tagd0)}</p>} />
              <Column header='Data cadastro' sortable body={item => <p>{this.util.formatarDataComTimestamp(item.dataCadastro)}</p>} />
              <Column header='Buscar saldo' sortable body={(pItem) => pItem.tags9 === '2' ?
                <Button
                  label="Atualizar saldo"
                  className="p-button-raised"
                  onClick={() => this.AtualizarSaldo(pItem)}
                />
                :
                <Button
                  label="Buscar saldo"
                  className="p-button-raised"
                  onClick={() => this.buscarSaldo(pItem)}
                />
              } />
              <Column header='Status' sortable body={(pItem) => <p
                style={{ textAlign: 'center', color: pItem.tags9 === '2' ? 'orange' : 'green' }}
              >{pItem.tags9 === '2' ? 'Aguardando' : 'Ok'}</p>} />

              <Column header='Detalhe' sortable body={(pItem) => <Button
                label="Detalhe"
                className="p-button-raised"
                onClick={() => this.selecionar(pItem)}
              />
              } />

            </DataTable>
          </div>
        </main>
      </div >
    );
  }
}
