import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Util from "../Util/Util";
import Constante from "../Util/Constante";
import { Toast } from 'primereact/toast';

export default class FormAlterarSenha extends Component {
    toast = createRef();
    util = new Util();
    constante = new Constante();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        carregando: false,
        inputAlterarSenhaError: {
            senha: false,
            senhaNova: false,
            confirmarNovaSenha: false,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.setState({ pessoaUsuario: pessoaUsuario });
    }



    async alterarSenha(e) {
        e.preventDefault();
        this.setState({ carregando: true });

        let pessoaUsuario = this.state.pessoaUsuario;
        const senhaAtual = e.target[0].value;
        const senhaNova = e.target[1].value;
        const senhaNovaConfirmacao = e.target[2].value;

        if (pessoaUsuario.senha !== senhaAtual) {
            this.toast.current.show([
                { severity: 'error', summary: 'Senha atual incorreta.' },
            ]);
            this.setState({ carregando: false });
            return;
        }

        if (senhaNova !== senhaNovaConfirmacao) {
            this.toast.current.show([
                { severity: 'error', summary: 'Senha nova não confere com a confirmação.' },
            ]);
            this.setState({ carregando: false });
            return;
        }

        pessoaUsuario.senha = senhaNova;

        e.target.reset();

        this.salvar(pessoaUsuario);
    }


    async salvar(pPessoa) {
        this.setState({ carregando: true });

        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        const retornoPessoa = await this.pessoaService.salvar(pessoaUsuario);
        pessoaUsuario = retornoPessoa.objeto;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            carregando: false,
        });

        if (this.props?.avancarFormulario) {
            this.props.avancarFormulario();
        }
    }

    validarInputsSenha(e) {
        const inputAlterarSenhaError = this.state.inputAlterarSenhaError;
        const listaChaveinputAlterarSenhaError = Object.keys(inputAlterarSenhaError);

        const senhaAtual = e.target[0].value;
        const senhaNova = e.target[1].value;
        const senhaNovaConfirmacao = e.target[2].value;

        for (let i = 0; i < listaChaveinputAlterarSenhaError.length; i++) {
            inputAlterarSenhaError[listaChaveinputAlterarSenhaError[i]] = false;
        }

        if (!senhaAtual) {
            inputAlterarSenhaError.senha = true;
        }

        if (!senhaNova) {
            inputAlterarSenhaError.senhaNova = true;
        }

        if (!senhaNovaConfirmacao) {
            inputAlterarSenhaError.confirmarNovaSenha = true;
        }

        let inputOK = true;

        for (let i = 0; i < listaChaveinputAlterarSenhaError.length; i++) {
            if (inputAlterarSenhaError[listaChaveinputAlterarSenhaError[i]]) {
                inputOK = false;
            }
        }

        this.setState({ inputAlterarSenhaError: inputAlterarSenhaError });

        return inputOK;
    }

    render() {
        return <>
            <Toast ref={this.toast} />

            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    const retorno = this.validarInputsSenha(e);
                    if (retorno) this.alterarSenha(e);
                }}
                style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20}} >
                <div>
                    <label>Senha atual</label><br />
                    <InputText
                        type="password"
                        style={{ width: '100%', borderColor: this.state.inputAlterarSenhaError.senha && 'var(--red-600)' }} />
                    {this.state.inputAlterarSenhaError.senha && <small style={{ color: 'var(--red-600)' }}>Senha atual inválida</small>}
                </div>
                <div>
                    <label>Nova senha</label><br />
                    <InputText
                        type="password"
                        style={{ width: '100%', borderColor: this.state.inputAlterarSenhaError.senhaNova && 'var(--red-600)' }} />
                    {this.state.inputAlterarSenhaError.senhaNova && <small style={{ color: 'var(--red-600)' }}>Senha nova inválida</small>}
                </div>
                <div>
                    <label>Cofirmar nova senha</label><br />
                    <InputText
                        type="password"
                        style={{ width: '100%', borderColor: this.state.inputAlterarSenhaError.confirmarNovaSenha && 'var(--red-600)' }} />
                    {this.state.inputAlterarSenhaError.confirmarNovaSenha && <small style={{ color: 'var(--red-600)' }}>Confirmação de senha nova inválida</small>}
                </div>
                {this.props?.avancarFormulario ?
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button className="p-button-link" loading={this.state.carregando} type="button" label="Gostaria de manter e avançar" onClick={() => this.props.avancarFormulario()} />

                        <Button loading={this.state.carregando} type="submit" label="Salvar" />
                    </div>
                    :
                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                        <Button loading={this.state.carregando} type="submits" label="Salvar" />
                    </div>
                }
            </form>
        </>
    }
}