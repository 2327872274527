import { Sidebar } from "primereact/sidebar";
import { Component } from "react";
import headerLogo from '../../Assets/Imagens/logo.png';

export default class HeaderAutenticacao extends Component {

  state = {
    sidebarVisivel: false,
  }

  render() {
    return <>
      <Sidebar visible={this.state.sidebarVisivel} onHide={() => this.setState({ sidebarVisivel: false })}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 30, }}>
        </div>
      </Sidebar>

      <div className="h-header">
        <header className="h-header-container">
          <img src={headerLogo} alt="Maxximus" style={{
            height: 80,
            width: 80,
            objectFit: "contain",
          }} />
        </header>
      </div>
    </>
  }
}