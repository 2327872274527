import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Component, createRef } from "react";
import { Navigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import Constante from "../Util/Constante";
import Util from "../Util/Util";
import HeaderAdm from "./Components/HeaderAdm";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";

export default class DetalheOperadora extends Component {
    toast = createRef();
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        pessoaOperadora: new Pessoa(),
        inputError: {
            nomeOperadora: false,
            id: false,
            estadoOperadora: false,
        },
        liberarAcesso: {
            operadora: false
        },
    }


    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');
        const parametro = await this.util.buscarParametros();
        let pessoaOperadora = new Pessoa();
        if (parametro) {
            let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro?.id);
            pessoaOperadora = retorno.objeto;
        }

        this.setState({
            pessoaUsuario: pessoaUsuario,
            pessoaOperadora: pessoaOperadora,
        })
    }

    async salvar() {
        let retorno = await this.validarInputs();
        if (!retorno) {
            return
        }
        let pessoaOperadora = this.state.pessoaOperadora;
        let retornoPessoa = await this.pessoaService.salvar(pessoaOperadora);
        pessoaOperadora = retornoPessoa.objeto;
        this.toast.current.show([
            { severity: 'success', summary: ' Alteração armazenadas com sucesso!' },
        ]);
        this.setState({
            pessoaOperadora: pessoaOperadora,
        })
    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }

    validarInputs() {
        let inputError = this.state.inputError;
        console.log(inputError);
        let listaKey = Object.keys(inputError);
        let inputOk = true;
        listaKey.forEach(key => inputError[key] = false);

        if (!this.state.pessoaOperadora.nome) {
            inputError.nomeOperadora = true
        }
     
        if (!this.state.pessoaOperadora.tagn3) {
            inputError.id = true
        }

        listaKey.forEach(key => {
            if (inputError[key]) {
                inputOk = false
            }
        })

        this.setState({ inputError: inputError })
        return inputOk
    }
    render() {
        return <div>
            {this.state.liberarAcesso.operadora && <Navigate to="/sistema/admin/operadora" />}

            <HeaderAdm />
            <main style={{ width: '95%', margin: '20px auto' }}>
                <Toast ref={this.toast} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised" onClick={() => this.navegar("operadora")} />
                        <div>
                            <h1 style={{ fontSize: 22 }}>Detalhe operadora</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Detalhe operadora</h2>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        <Button loading={this.state.salvarCarregando} label="Salvar" className="p-button-raised" onClick={() => this.salvar()} />
                    </div>
                </div>

                <form
                    onSubmit={e => {
                        e.preventDefault();
                        this.salvar();
                    }}
                    style={{ display: "flex", flexDirection: "column", gap: 10, }}>
                    <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 10 }}><br />
                        <div style={{ display: "flex", alignItems: "center", gap: 5, }}>
                            <Button style={{ width: 30, height: 30 }} type="button" icon="pi pi-user" className="p-button-rounded p-button-help p-button-outlined" aria-label="Home" />
                            <h3>Dados da operadora</h3>
                        </div>

                        <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr", gap: 20, marginTop: 20 }}>
                            <div>
                                <label>Nome operadora</label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        pessoaOperadora: {
                                            ...this.state.pessoaOperadora, nome: pTexto.target.value
                                        }
                                    })}
                                    value={this.state.pessoaOperadora.nome || ''}
                                    style={{
                                        width: "100%",
                                        height: 40,
                                        borderColor: this.state.inputError.nomeOperadora && "var(--red-600)",
                                    }}
                                />
                                {this.state.inputError.nomeOperadora && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Nome inválido
                                    </small>
                                )}
                            </div>
                         
                            <div>
                                <label>ID da operadora(OGT)</label><br />
                                <InputNumber
                                    onChange={pTexto => this.setState({
                                        pessoaOperadora: {
                                            ...this.state.pessoaOperadora, tagn3: pTexto.value
                                        }
                                    })}
                                    useGrouping={false}
                                    value={this.state.pessoaOperadora.tagn3 || ''}
                                    inputStyle={{
                                        width: "100%",
                                        height: 40,
                                        borderColor: this.state.inputError.id && "var(--red-600)",
                                    }}
                                /><br />
                                {this.state.inputError.id && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Id inválido
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </main>
        </div>
    }
}