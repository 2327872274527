import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Component, createRef } from "react";
import Logo from '../Assets/Imagens/logo.png';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Constante from '../Util/Constante';
import Util from '../Util/Util';
export default class Login extends Component {
    mensagem = createRef();
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        inputError: {
            email: false,
            senha: false,
        },
        carregando: false
    };

    async validarAcesso(pEvento) {
        pEvento.preventDefault();

        let pessoaUsuario = this.state.pessoaUsuario;
        let pessoa = null;
        let inputOK = true;
        const inputError = this.state.inputError;
        const listaPesquisa = [];


        pessoaUsuario.emailPlataformaLirida = pEvento.target[0].value;
        pessoaUsuario.senha = pEvento.target[1].value;

        Object.keys(inputError).forEach((pKey) => (inputError[pKey] = false));

        if (!pessoaUsuario.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!pessoaUsuario.senha) {
            inputError.senha = true;
        }

        Object.keys(inputError).forEach((pKey) => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        });

        if (!inputOK) {
            this.setState({ inputError: inputError });
            return;
        }

        this.setState({ carregando: true });

        this.mensagem.current.clear();

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            )
        );

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.email_plataforma_lirida",
                pessoaUsuario.emailPlataformaLirida,
                "AND",
                "TEXTO"
            )
        );

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "AND ( pessoa.seq_pessoa_perfil = '" +
                this.constante.seqPerfilAdministrador +
                "'",
                this.constante.seqPerfilAdministrador,
                "AND",
                "MANUAL"
            )
        );

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "OR pessoa.seq_pessoa_perfil = '" +
                this.constante.seqPerfilEmpresa +
                "')",
                this.constante.seqPerfilEmpresa,
                "OR",
                "MANUAL"
            )
        );

        const retornoListaPessoa = await this.pessoaService.listarComFiltro(listaPesquisa);

        retornoListaPessoa?.objeto?.forEach((pPessoa) => {
            if (
                pPessoa.emailPlataformaLirida === pessoaUsuario.emailPlataformaLirida
            ) {
                pessoa = pPessoa;
            }
        });

        if (!pessoa) {
            this.mensagem.current.show([
                { severity: "error", summary: "Usuário não encontrado", sticky: true },
            ]);
            this.setState({ carregando: false });
            return;
        }

        if (pessoa.senha !== pessoaUsuario.senha) {
            this.mensagem.current.show([
                { severity: "error", summary: "Senha inválida", sticky: true },
            ]);
            this.setState({ carregando: false });
            return;
        }

        pessoaUsuario = pessoa;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        if (pessoaUsuario.seqPessoaPerfil === this.constante.seqPerfilAdministrador) {
            window.location.href = "/sistema/admin/empresa";
        }

        if (pessoaUsuario.seqPessoaPerfil === this.constante.seqPerfilSuperAdministrador) {
            window.location.href = "/sistema/admin/financeiro/conta_pagar";
        }

        if (pessoaUsuario.seqPessoaPerfil === this.constante.seqPerfilEmpresa) {
            window.location.href = "/sistema/empresa/cartao";
        }

    }

    render() {
        return (
            <main style={{ width: "100%", padding: "5rem" }}>
                <Messages
                    style={{
                        alignSelf: "center",
                    }}
                    ref={this.mensagem}
                />

                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={Logo}
                            alt="MAXXIMUS"
                            style={{
                                width: 400,
                                height: 400,
                                objectFit: "contain",
                            }}
                        />
                    </div>

                    <div
                        style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <form
                            style={{ display: "flex", flexDirection: "column", gap: 20 }}
                            onSubmit={(pEvento) => this.validarAcesso(pEvento)}
                        >
                            <h1>Login</h1>
                            <div>
                                <label>Email</label>
                                <br />
                                <InputText
                                    type="text"
                                    style={{
                                        width: "100%",
                                        borderColor: this.state.inputError.email && "red",
                                    }}
                                />
                                {this.state.inputError.email && (
                                    <small style={{ color: "red" }}>Email inválido</small>
                                )}
                            </div>
                            <div>
                                <label>Senha</label>
                                <br />
                                <InputText
                                    type="password"
                                    style={{
                                        width: "100%",
                                        borderColor: this.state.inputError.senha && "red",
                                    }}
                                />
                                {this.state.inputError.senha && (
                                    <small style={{ color: "red" }}>Senha inválida</small>
                                )}
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    gap: 10,
                                    marginTop: 10,
                                }}
                            >
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    <Button
                                        style={{ padding: 0 }}
                                        type="button"
                                        label="Recuperar senha"
                                        className="p-button-link"
                                        onClick={() => (window.location.href = "/recuperar_senha")}
                                    />
                                    <Button
                                        style={{ padding: 0 }}
                                        type="button"
                                        label="Cadastre-se"
                                        className="p-button-link"
                                        onClick={() => (window.location.href = "/cadastro")}
                                    />
                                </div>

                                <Button
                                    style={{ marginTop: 20 }}
                                    loading={this.state.carregando}
                                    type="submit"
                                    label="Entrar"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        );
    }
}
