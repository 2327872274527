import { Button } from "primereact/button";
import { Component } from "react";
import Constante from "../../Util/Constante";
import Util from "../../Util/Util";
import logo from '../../Assets/Imagens/logo.png';
import { Dropdown } from "primereact/dropdown";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";

export default class HeaderAdm extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)

    state = {
        pessoaUsuario: new Pessoa(),
        pessoaEmpresa: new Pessoa(),
        dropdownItemVisivel: false,
        dropdownFinanceiroVisivel: false,
        listaEmpresa: [],


    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.listarEmpresa()
        this.setState({
            pessoaUsuario: pessoaUsuario,
        });
    }

    async salvarEmpresaLocalStorage() {

        let pessoaEmpresa = this.state.pessoaEmpresa
        this.util.salvarLocalStorage("PESSOA_EMPRESA", pessoaEmpresa)
    }


    async listarEmpresa() {
        this.listaPesquisa = [

            this.util.criarObjetoPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa.seq_pessoa_perfil",
                this.constante.seqPerfilEmpresa,
                "AND",
                "TEXTO"
            ),

        ]
        this.retornoListaPequisa = await this.pessoaService.listarComFiltro(this.listaPesquisa)
        this.listaEmpresa = this.retornoListaPequisa.objeto

        console.log("lista de empresa", this.listaEmpresa);

        this.setState({
            listaEmpresa: this.listaEmpresa,
        })

    }



    render() {
        return <header style={{ height: 50, backgroundColor: this.constante.azul, padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <img alt='Maxximus' src={logo} style={{ width: 150, height: 50, objectFit: "contain" }} />

            <nav style={{ display: 'flex', alignItems: "center" }}>

                <Button
                    label="Empresas"
                    onClick={() => window.location.href = "/sistema/admin/empresa"}
                    className="p-button-link"
                    style={{ color: 'white' }} />
                <Button
                    label="Cartões"
                    onClick={() => window.location.href = "/sistema/admin/cartao"}
                    className="p-button-link"
                    style={{ color: 'white' }} />

                <Button
                    label="Operadora"
                    onClick={() => window.location.href = "/sistema/admin/operadora"}
                    className="p-button-link"
                    style={{ color: 'white' }} />

                <Button
                    label="Pedidos"
                    onClick={() => window.location.href = "/sistema/admin/pedido"}
                    className="p-button-link"
                    style={{ color: 'white' }} />

                <div>
                    <Dropdown
                        onChange={pItem => this.setState({ pessoaEmpresa: pItem.target.value })}
                        onClick={this.salvarEmpresaLocalStorage()}
                        value={this.state.pessoaEmpresa}
                        options={this.state.listaEmpresa}
                        optionLabel='nome'
                        placeholder={!this.state?.pessoaEmpresa?.nome ? "Selecione a empresa" : this.state?.pessoaEmpresa?.nome}
                        filter
                        filterBy="nome"
                        style={{
                            width: "100%",
                            height: 40,
                            // backgroundColor: this.constante.azul,
                            color: "white",
                            border: "none"
                            // borderColor: this.state.inputError.empresa && "var(--red-600)",
                        }}
                    />
                </div>


                {/* TELA SUPER ADM */}
                {this.state.pessoaUsuario.seqPessoaPerfil === this.constante.seqPerfilSuperAdministrador &&
                    <div style={{ position: 'relative' }}>
                        <Button
                            label="Financeiro"
                            onClick={() => this.setState({ dropdownFinanceiroVisivel: !this.state.dropdownFinanceiroVisivel })}
                            className="p-button-link"
                            style={{ color: 'white' }} />
                        {this.state.dropdownFinanceiroVisivel &&
                            <div style={{ width: 200, position: 'absolute', padding: 5, backgroundColor: "white", boxShadow: "0 0 10px rgba(0,0,0,0.5)", zIndex: 999 }}>
                                <Button
                                    label="Contas à pagar"
                                    style={{ textAlign: 'left' }}
                                    onClick={() => window.location.href = "/sistema/admin/financeiro/conta_pagar"}
                                    className="p-button-link" />
                                <Button
                                    label="Contas à receber"
                                    style={{ textAlign: 'left' }}
                                    onClick={() => window.location.href = "/sistema/admin/financeiro/conta_receber"}
                                    className="p-button-link" />
                            </div>
                        }
                    </div>}
                <Button
                    label="Perfil"
                    onClick={() => window.location.href = "/sistema/admin/perfil"}
                    className="p-button-link"
                    style={{ color: 'white' }} />
                <Button
                    label="Sair"
                    onClick={() => {
                        this.util.removerLocalStorage("PESSOA_USUARIO");
                        window.location.href = "/";
                    }}
                    className="p-button-link"
                    style={{ color: 'white' }} />
            </nav>
        </header>
    }
}