import { Component, createRef } from "react";
import { Toast } from "primereact/toast";


import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Constante from "../Util/Constante";
import Util from "../Util/Util";
import HeaderAdm from "./Components/HeaderAdm";
import { Button } from "primereact/button";
import { Navigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { Calendar } from "primereact/calendar";

export default class DetalheCartão extends Component {
    toast = createRef();
    util = new Util()
    constante = new Constante()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)

    state = {
        pessoaFuncionario: new Pessoa(),
        pessoaOperadora: new Pessoa(),
        pessoaEmpresa: new Pessoa(),

        parametrosUrl: '',
        salvarCarregando: false,
        vModalDeletar: false,
        deletarCarregando: false,

        listaOperadora: [],

        liberarAcesso: {
            cartao: false,
        },

        inputsError: {
            nome: false,
            cpf: false,
            matricula: false,
            nCartao: false,
            diasTrabalhado: false,
            valorDiario: false
        }
    }


    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {
        let retornoParametrosUrl = this.util.buscarParametros()
        const retornoPessoaFuncionario = await this.pessoaService.buscarPorSeqPessoa(retornoParametrosUrl.id)
        let pessoaFuncionario = retornoPessoaFuncionario.objeto;

        let pessoaOperadora = new Pessoa();

        if (pessoaFuncionario.tagd0) {
            let dataUltimaAtualizacao = pessoaFuncionario.tagd0.split("T")[0];
            let data = new Date();
            data.setDate(dataUltimaAtualizacao.split("-")[0]);
            data.setMonth(Number(dataUltimaAtualizacao.split("-")[1]) - 1);
            data.setFullYear(dataUltimaAtualizacao.split("-")[2]);

            pessoaFuncionario.tagd0 = data;
        }

        this.listarOperadora()
        this.listarRelacionamento(pessoaFuncionario)
        this.setState({ pessoaFuncionario: pessoaFuncionario, pessoaOperadora: pessoaOperadora });
    }

    async salvar() {

        let retornoValidacao = await this.validarInputs()
        if (!retornoValidacao) {
            return
        }

        let pessoaFuncionario = this.state.pessoaFuncionario
        pessoaFuncionario.seqPessoaPerfil = this.constante.seqPerfilFuncionarioOK;
        pessoaFuncionario.tags2 = this.state.pessoaOperadora.nome;
        pessoaFuncionario.tagn3 = this.state.pessoaOperadora.tagn3

        let retorno = await this.pessoaService.salvar(pessoaFuncionario)
        pessoaFuncionario = retorno.objeto

        this.toast.current.show([
            { severity: 'success', summary: ' Alteração armazenadas com sucesso!' },
        ]);
        this.setState({ pessoaFuncionario: pessoaFuncionario, salvarCarregando: false })
    }

    validarInputs() {
        let inputsError = this.state.inputsError;
        let listaKeys = Object.keys(inputsError);
        let inputsOk = true;

        listaKeys.forEach(key => {
            inputsError[key] = false
        })

        if (!this.state.pessoaFuncionario.nome) {
            inputsError.nome = true;
        }

        if (this.state.pessoaFuncionario.documento && this.state.pessoaFuncionario.documento.length !== 14) {
            inputsError.cpf = true;
        }

        if (!this.state.pessoaFuncionario.documento) {
            inputsError.cpf = true;
        }

        if (!this.state.pessoaFuncionario.tags0) {
            inputsError.matricula = true;
        }

        if (!this.state.pessoaFuncionario.tags1) {
            inputsError.nCartao = true;
        }


        if (!this.state.pessoaFuncionario.tagn0) {
            inputsError.diasTrabalhado = true;
        }


        if (!this.state.pessoaFuncionario.tagn1) {
            inputsError.valorDiario = true;
        }

        listaKeys.forEach(key => {
            if (inputsError[key]) {
                inputsOk = false
            }
        })
        this.setState({
            inputsError: inputsError
        })
        return inputsOk
    }

    async listarOperadora() {
        let listaPesquisa = [

            this.util.criarObjetoPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa.seq_pessoa_perfil",
                this.constante.seqPerfilOperadora,
                "AND",
                "TEXTO"
            ),

        ]
        this.retornoListaPequisa = await this.pessoaService.listarComFiltro(listaPesquisa)
        let listaOperadora = this.retornoListaPequisa.objeto
        console.log("lista operadora", listaOperadora);

        let pessoaFuncionario = this.state.pessoaFuncionario
        let pessoaOperadora = new Pessoa()
        for (let i = 0; i < listaOperadora.length; i++) {
            if (listaOperadora[i].tagn3 === pessoaFuncionario.tagn3) {
                pessoaOperadora = listaOperadora[i]
            }
        }

        this.setState({
            listaOperadora: listaOperadora,
            pessoaOperadora: pessoaOperadora
        })

    }

    async listarRelacionamento(pPessoaFuncionario) {
        let pessoaFuncionario = pPessoaFuncionario
        const retorno = await this.pessoaService.listarRelacionamento(pessoaFuncionario)
        let pessoaEmpresa = retorno.objeto[0]

        console.log("Pessoa empresaaaa", pessoaEmpresa)

        this.setState({ pessoaEmpresa: pessoaEmpresa })
    }


    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }

    render() {
        return <div>
            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.cartao && <Navigate to="/sistema/admin/cartao" />}

            <Dialog header="Alerta" visible={this.state.vModalDeletar} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletar: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar funcionario?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletar();
                            this.setState({ vModalDeletar: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletar: false, })} />
                    </div>
                </div>
            </Dialog>


            {/* TELA */}

            <HeaderAdm />

            <main style={{ width: '95%', margin: '20px auto' }}>
                <Toast ref={this.toast} />

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised" onClick={() => this.navegar("cartao")} />
                        <div>
                            <h1 style={{ fontSize: 22 }}>Detalhe Cartão</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Detalhe Cartão</h2>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        <Button loading={this.state.salvarCarregando} label="Salvar" className="p-button-raised" onClick={() => this.salvar()} />
                        {/* <Button loading={this.state.deletarCarregando} label="Deletar" className="p-button-danger" onClick={() => this.setState({ vModalDeletar: true })} /> */}
                    </div>
                </div>


                <form
                    onSubmit={e => {
                        e.preventDefault();
                        this.salvar();
                    }}
                    style={{ display: "flex", flexDirection: "column", gap: 10, }}>


                    {/* DADOS FUNCIONARIO */}

                    <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 10 }}><br />
                        <div style={{ display: "flex", alignItems: "center", gap: 5, }}>
                            <Button style={{ width: 30, height: 30 }} type="button" icon="pi pi-user" className="p-button-rounded p-button-help p-button-outlined" aria-label="Home" />
                            <h3>Dados Funcionários</h3>
                        </div>

                        <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr", gap: 20, marginTop: 20 }}>
                            <div>
                                <label>Nome</label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        pessoaFuncionario:
                                            { ...this.state.pessoaFuncionario, nome: pTexto.target.value }
                                    })}

                                    value={this.state.pessoaFuncionario.nome || ''}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputsError.nome && "var(--red-600)" }} />
                                {this.state.inputsError.nome && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Nome inválido
                                    </small>
                                )}
                            </div>

                            <div>
                                <label>CPF</label><br />
                                <InputText

                                    type="tel"
                                    value={this.state.pessoaFuncionario.documento || ''}
                                    onChange={e => {
                                        if (e.target.value.length > 14) return;

                                        this.setState({
                                            pessoaFuncionario: {
                                                ...this.state.pessoaFuncionario,
                                                documento: this.util.formatarCPF(e.target.value)
                                            }
                                        })
                                    }}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputsError.cpf && "var(--red-600)" }} />
                                {this.state.inputsError.cpf && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Cpf inválido
                                    </small>
                                )}
                            </div>

                            <div>
                                <label>Matricula</label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        pessoaFuncionario:
                                            { ...this.state.pessoaFuncionario, tags0: pTexto.target.value }
                                    })}

                                    value={this.state.pessoaFuncionario.tags0 || ''}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputsError.matricula && "var(--red-600)" }} />
                                {this.state.inputsError.matricula && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Matricula inválida
                                    </small>
                                )}
                            </div>

                            <div>
                                <label>N° do cartão </label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        pessoaFuncionario:
                                            { ...this.state.pessoaFuncionario, tags1: pTexto.target.value }
                                    })}

                                    value={this.state.pessoaFuncionario.tags1 || ''}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputsError.nCartao && "var(--red-600)" }} />
                                {this.state.inputsError.nCartao && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Número inválido
                                    </small>
                                )}
                            </div>

                            <div>
                                <label>Operadora</label><br />
                                <Dropdown
                                    onChange={pItem => this.setState({ pessoaOperadora: pItem.value })}
                                    value={this.state.pessoaOperadora}
                                    options={this.state.listaOperadora}
                                    optionLabel='nome'
                                    placeholder='Selecione a operadora'
                                    filter
                                    filterBy="nome"
                                    style={{
                                        width: "100%",
                                        height: 40,
                                    }}
                                />
                            </div>

                            <div>
                                <label>Dias trabalhados</label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        pessoaFuncionario:
                                            { ...this.state.pessoaFuncionario, tagn0: pTexto.target.value }
                                    })}
                                    value={this.state.pessoaFuncionario.tagn0 || ''}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputsError.diasTrabalhado && "var(--red-600)" }} />
                                {this.state.inputsError.diasTrabalhado && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Dias trabalhado inválido
                                    </small>
                                )}
                            </div>

                            <div>
                                <label>Valor diário</label><br />
                                <InputNumber
                                    onChange={pTexto => this.setState({
                                        pessoaFuncionario:
                                            { ...this.state.pessoaFuncionario, tagn1: pTexto.value }
                                    })}
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    value={this.state.pessoaFuncionario.tagn1 || ''}
                                    style={{ width: '100%', height: 40 }}
                                    inputStyle={{ borderColor: this.state.inputsError.valorDiario && "var(--red-600)" }}
                                /><br />
                                {this.state.inputsError.valorDiario && (
                                    <small style={{ color: "var(--red-600)" }}>
                                        Valor inválido
                                    </small>
                                )}
                            </div>

                            <div>
                                <label>Saldo</label><br />
                                <InputNumber
                                    disabled
                                    onChange={(pTexto) => this.setState({
                                        pessoaFuncionario:
                                            { ...this.state.pessoaFuncionario, tagn2: pTexto.value }
                                    })}
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    value={this.state.pessoaFuncionario.tagn2 || ''}
                                    style={{ width: '100%', height: 40 }} />
                            </div>

                            <div>
                                <label>Data da ultima consulta</label><br />
                                <Calendar
                                    disabled
                                    value={this.state.pessoaFuncionario.tagd0}
                                    showButtonBar
                                    style={{ width: "100%", height: 40 }}
                                    dateFormat="dd/mm/yy"
                                    placeholder="dd/mm/yyyy"
                                />
                            </div>

                            <div>
                                <label>Empresa</label><br />
                                <InputText
                                    disabled

                                    value={this.state.pessoaEmpresa?._pessoaRelacionadaNome || ''}
                                    style={{ width: '100%', height: 40 }} />
                            </div>
                        </div>
                    </div>
                </form>
            </main>

        </div>
    }
}