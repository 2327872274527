export class OgtService {
  constructor(pUrlBase) {
    this.urlBase = pUrlBase;
  }

  async validarAcessoOgt(pCpf, pSenha) {
    let cabecalho = new Headers();
    cabecalho.append("Content-Type", "application/json");

    let objeto = {
      cpf: pCpf,
      senha: pSenha,
    };

    console.log("Cabeçalho", cabecalho);
    let res = await fetch('/api/v1/autenticar', {
      method: 'POST',
      headers: cabecalho,
      body: JSON.stringify(objeto),
      redirect: 'follow'
    });

    res = await res.json();
    return res;
  };

  async buscarListaCompletaOperadora(pToken) {
    let cabecalho = new Headers();
    cabecalho.append("Content-Type", "application/json");
    cabecalho.append("Authorization", `Bearer ${pToken}`);

    let res = await fetch('/api/v1/operadoras/obterListaCompleta', {
      method: 'GET',
      headers: cabecalho,
      redirect: 'follow'
    })
    res = await res.json();
    return res;
  };

  async buscarIdOperadoraPorNome(pNomeOperadora, pToken) {
    let cabecalho = new Headers();
    cabecalho.append("Content-Type", "application/json");
    cabecalho.append("Authorization", `Bearer ${pToken}`);

    let res = await fetch('/api/v1/operadoras/buscarPornome?nomeOperadora=' + pNomeOperadora, {
      method: 'GET',
      headers: cabecalho,
      redirect: 'follow'
    })
    res = await res.json();
    return res;
  };


  async enviarLote(pPedido, pToken) {
    let cabecalho = new Headers();
    cabecalho.append("Content-Type", "application/json");
    cabecalho.append("Authorization", `Bearer ${pToken}`);
    let res = await fetch('/api/v1/Pedido/enviarLote', {
      method: 'POST',
      headers: cabecalho,
      redirect: 'follow',
      body: JSON.stringify(pPedido)
    });
    res = await res.json();
    return res;
  }

  async obterResultado(pIdPedido, pNrSolicitacao, pToken) {
    let cabecalho = new Headers();
    cabecalho.append("Content-Type", "application/json");
    cabecalho.append("Authorization", `Bearer ${pToken}`);

    let res = await fetch(`/api/v1/Pedido/obterResultado?idPedido=${pIdPedido}&nrSolicitacao=${pNrSolicitacao}`, {
      method: 'GET',
      headers: cabecalho,
      redirect: 'follow',
    });
    res = await res.json();
    return res;
  }

}