import React, { Component, createRef } from "react";

import { Button } from "primereact/button";

import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Constante from "../Util/Constante";
import Util from "../Util/Util";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";

export default class DetalheEmpresa extends Component {
    toast = createRef();
    util = new Util()
    constante = new Constante()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)


    state = {
        urlBase: '',
        pessoaUsuario: new Pessoa(),
        pessoa: new Pessoa(),
        tela: 1,
        tabelaCarregando: false,
        salvarCarregando: false,

        listaCartao: []
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();
        const pessoa = this.props.pessoa;
        console.log(pessoa);

        this.listar(pessoa)
        this.setState({
            urlBase: urlBase,
            pessoaUsuario: pessoaUsuario,
            pessoa: pessoa,
        });

    }

    async listar(pPessoa) {
        this.setState({ tabelaCarregando: true });

        const listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa_relacionamento.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa_relacionamento.seq_pessoa_relacionada";
        pesquisa.conteudo = pPessoa._seqPessoa;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa_relacionamento.seq_tipo_relacionamento";
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoEmpresa;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);


        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        let listaCartao = retorno.objeto;

        console.log(listaPesquisa);
        console.log(listaCartao);

        this.setState({
            listaCartao: listaCartao,
            tabelaCarregando: false,

        })
        return listaCartao;

    }

    async salvar() {
        this.setState({ salvarCarregando: true })
        let pessoa = this.state.pessoa;
        let retorno = await this.pessoaService.salvar(pessoa);
        console.log(retorno)

        this.toast.current.show([
            { severity: 'success', summary: ' Alteração armazenadas com sucesso!' },
        ]);
        this.setState({
            salvarCarregando: false
        })




    }
    render() {
        return (
            <div>
                <main style={{ width: '98%', margin: '0 auto', marginTop: 20 }}>
                    <Toast ref={this.toast} />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5 }}>

                        <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                            <Button
                                label={<i className="pi pi-arrow-left" />}
                                onClick={() => {
                                    this.props.listar()
                                    this.props.setState({
                                        pessoa: new Pessoa(),
                                        tela: 1
                                    })
                                }} />
                            <h1 style={{ fontSize: 22.5 }}>Detalhe da Empresa</h1>
                        </div>


                        <Button
                            label="Salvar"
                            loading={this.state.salvarCarregando}
                            onClick={() => this.salvar()} />
                    </div>
                    <div style={{ display: "flex", padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5, marginTop: 20, gap: 20 }}>
                        <div style={{ width: 200, height: 200, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                            {this.state.pessoa?.foto ?
                                <img
                                    src={this.state.urlBase + this.state.pessoa.foto}
                                    style={{ width: 200, height: 200, objectFit: 'contain' }} />
                                :
                                <p>sem imagem</p>
                            }
                        </div>

                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", marginTop: 20, gap: 20 }}>
                            <div>
                                <label>Nome</label><br />
                                <InputText
                                    disabled
                                    style={{ width: '100%', }}
                                    value={this.state.pessoa?.nome || ""} />
                            </div>
                            <div>
                                <label>CNPJ</label><br />
                                <InputText
                                    disabled
                                    style={{ width: '100%', }}
                                    value={this.state.pessoa?.documento || ""} />
                            </div>
                            <div>
                                <label>Email</label><br />
                                <InputText
                                    disabled
                                    style={{ width: '100%', }}
                                    value={this.state.pessoa?.emailPlataformaLirida || ""} />
                            </div>
                            <div>
                                <label>Telefone</label><br />
                                <InputText
                                    disabled
                                    style={{ width: '100%', }}
                                    value={this.state.pessoa?.telefonePlataformaLirida || ""} />
                            </div>

                            <div>
                                <label>Login operadora (OGT)</label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa, tags3: pTexto.target.value
                                        }
                                    })}
                                    style={{ width: '100%', }}
                                    value={this.state.pessoa?.tags3 || ""} />
                            </div>


                            <div>
                                <label>Senha operadora (OGT)</label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa, tags4: pTexto.target.value
                                        }
                                    })}
                                    style={{ width: '100%', }}
                                    value={this.state.pessoa?.tags4 || ""} />
                            </div>

                            <div>
                                <label>Data Cadastro Inicial</label><br />
                                <Calendar
                                    disabled
                                    showButtonBar
                                    style={{ width: "100%", height: 40 }}
                                    placeholder="dd/mm/yyyy"
                                    value={this.util.formatarDataCalendar(this.state.pessoa?.dataCadastro) || ""}
                                />
                            </div>
                        </div>
                    </div>
                    <TabView style={{ marginTop: 10 }}>
                        <TabPanel header='Cartões'>
                            <DataTable
                                selectionMode="single"
                                showGridlines
                                removableSort
                                paginator
                                responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                loading={this.state.tabelaCarregando}
                                style={{ width: "100%" }}
                                value={this.state.listaCartao}
                                onSelectionChange={e => this.selecionar(e.value)}
                            >
                                <Column header='Matricula' field='tags0' sortable />
                                <Column header='Nome' field='nome' sortable />
                                <Column header='CPF' field='documento' sortable />
                                <Column header='N. Cartão' field='tags1' sortable />
                                {/* <Column header='Empresa' field='tags2' sortable /> */}
                                <Column header='Operadora' field='tags2' sortable />
                                {/* <Column header='Login Operadora' field='tags4' sortable />
                                <Column header='Senha Operadora' field='tags5' sortable /> */}
                                <Column header='Data cadastro' sortable body={item => <p>{this.util.formatarDataComTimestamp(item.dataCadastro)}</p>} />
                                {/* <Column header='Iconsistencia' field='seqPessoaPerfil' sortable body={(pItem) => <p>{pItem.seqPessoaPerfil === this.constante.seqPerfilFuncionarioOK ? 'Não' : 'Sim'}</p>} /> */}
                            </DataTable>
                        </TabPanel>
                    </TabView>

                </main>
            </div>
        );
    }
}
