import { Component } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import FormDadosPessoais from "../Components/FormDadosPessoais";
import FormDadosBancarios from "../Components/FormDadosBancarios";
import FormAlterarSenha from "../Components/FormAlterarSenha";
import FormEndereco from "../Components/FormEndereco";
import Constante from "../Util/Constante";
import Util from "../Util/Util";
import HeaderAdm from "./Components/HeaderAdm";

export default class PerfilAdministrador extends Component {
    util = new Util();
    constante = new Constante();

    state = {
        pessoaUsuario: new Pessoa(),
    };

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.setState({ pessoaUsuario: pessoaUsuario });
    }

    render() {
        return (
            <div>
                <HeaderAdm />
                <main
                    style={{
                        maxWidth: "90%",
                        width: 700,
                        margin: "0 auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 20,
                        padding: "20px 0 200px 0",
                    }}
                >
                    <TabView style={{ width: 700, margin: "0 auto" }}>
                        <TabPanel header="Dados pessoais" style={{ width: "100%" }}>
                            <FormDadosPessoais />
                        </TabPanel>
                        <TabPanel header="Endereço" style={{ width: "100%" }}>
                            <FormEndereco />
                        </TabPanel>
                        {this.state.pessoaUsuario.seqPessoaPerfil === this.constante.seqPerfilSuperAdministrador &&
                            <TabPanel header="Dados bancários" style={{ width: "100%" }}>
                                <FormDadosBancarios />
                            </TabPanel>}
                        <TabPanel header="Alterar senha" style={{ width: "100%" }}>
                            <FormAlterarSenha />
                        </TabPanel>
                        {/* <TabPanel header="Taxas" style={{ width: "100%" }}>
                            <form
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 20,
                                }}
                            >
                                <div>
                                    <label>Taxa Boleto</label>
                                    <br />
                                    <InputText
                                        disabled
                                        value={`R$ ${this.state.pessoaUsuario.taxaBoleto}`}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div>
                                    <label>Taxa Cartão de Crédito</label>
                                    <br />
                                    <InputText
                                        disabled
                                        value={`${this.state.pessoaUsuario.taxaCredito}%`}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div>
                                    <label>Taxa Pix</label>
                                    <br />
                                    <InputText
                                        disabled
                                        value={`R$ ${this.state.pessoaUsuario.taxaPix}`}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </form>
                        </TabPanel> */}
                    </TabView>
                </main>
            </div>
        );
    }
}