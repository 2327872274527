import React, { Component, createRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { Navigate } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import HeaderEmpresa from "./Components/HeaderEmpresa";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Constante from "../Util/Constante";
import Util from "../Util/Util";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";

export default class EmpresaCartao extends Component {
  constante = new Constante();
  mensagem = createRef();
  toast = createRef();
  util = new Util();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaFuncionario: new Pessoa(),
    pessoaOperadora: new Pessoa(),
    listaCartao: [],
    listaOperadora: [],
    vModalNovo: false,
    vModaExcel: false,
    tabelaCarregando: false,
    salvarCarregando: false,
    liberarAcesso: {
      detalheCartao: false,
    },

    inputError: {
      nome: false,
      cpf: false,
      matricula: false,
      numeroCartao: false,
      empresa: false,
      arquivo: false,
      operadora: false,
      loginOperadora: false,
      senhaOperadora: false,
      diasTrabalhado: false,
      valorDiario: false,
    },
    filtro: {
      nome: '',
      dataCadastroInicial: '',
      dataCadastroFinal: '',
      inconsistencia: '',
      documento: '',
    }
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let listaCartao = await this.listar(pessoaUsuario);
    let listaOperadora = await this.listarOperadora();
    this.setState({
      pessoaUsuario: pessoaUsuario,
      listaCartao: listaCartao,
      listaOperadora: listaOperadora,
    })
  }

  async salvar() {
    this.setState({ salvarCarregando: true })
    this.mensagem.current.clear();
    let pessoaFuncionario = this.state.pessoaFuncionario;
    pessoaFuncionario.seqUsuario = this.constante.seqUsuario;
    pessoaFuncionario.seqPessoaPerfil = this.constante.seqPerfilFuncionarioOK;
    pessoaFuncionario.tipoDocumento = "CPF";
    pessoaFuncionario.situacao = "ATIVO";
    pessoaFuncionario.tags2 = this.state.pessoaOperadora.nome;
    pessoaFuncionario.tagn3 = this.state.pessoaOperadora.tagn3;
    let retorno = await this.pessoaService.salvar(pessoaFuncionario);
    pessoaFuncionario = retorno.objeto;

    let pessoaRelacionamento = new PessoaRelacionamento();
    pessoaRelacionamento.seqUsuario = this.constante.seqUsuario;
    pessoaRelacionamento.seqPessoa = pessoaFuncionario._seqPessoa;
    pessoaRelacionamento.seqPessoaRelacionada = this.state.pessoaUsuario._seqPessoa;
    pessoaRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoEmpresa;
    retorno = await this.pessoaService.salvarRelacionamento(pessoaRelacionamento);
    console.log(retorno);

    this.listar()

    this.resetar();
    this.mensagem.current.show([
      {
        severity: "success",
        summary: "Cartão cadastrado com sucesso.",
        sticky: true,
      },
    ]);
  }


  resetar() {
    let inputError = this.state.inputError;
    let listaKey = Object.keys(inputError);
    listaKey.forEach(key => inputError[key] = false);
    this.setState({
      vModalNovo: false,
      inputError: inputError,
      salvarCarregando: false,
      pessoaFuncionario: new Pessoa(),
      pessoaOperadora: new Pessoa(),
    })
  }

  validarInputs() {
    let inputError = this.state.inputError;
    console.log(inputError);
    let listaKey = Object.keys(inputError);
    let inputOk = true;
    listaKey.forEach(key => inputError[key] = false);

    if (!this.state.pessoaFuncionario.nome) {
      inputError.nome = true
    }

    if (!this.state.pessoaFuncionario.documento) {
      inputError.cpf = true
    }

    if (this.state.pessoaFuncionario.documento && this.state.pessoaFuncionario.documento < 14) {
      inputError.cpf = true
    }

    if (!this.state.pessoaFuncionario.tags0) {
      inputError.matricula = true;
    }


    if (!this.state.pessoaFuncionario.tags1) {
      inputError.numeroCartao = true;
    }


    if (!this.state.pessoaOperadora._seqPessoa) {
      inputError.operadora = true;
    }

    if (!this.state.pessoaFuncionario.tagn0) {
      inputError.diasTrabalhado = true;
    }

    if (!this.state.pessoaFuncionario.tagn1) {
      inputError.valorDiario = true;
    }

    listaKey.forEach(key => {
      if (inputError[key]) {
        inputOk = false
      }
    })

    this.setState({ inputError: inputError })
    return inputOk
  }

  selecionar(pPessoaFuncionario) {
    let pessoaFuncionario = pPessoaFuncionario
    console.log('funcionario selecionado', pessoaFuncionario);
    this.setState({ pessoaFuncionario: pessoaFuncionario })
    this.navegar('detalheCartao')
  }

  navegar(pTela) {
    const liberarAcesso = this.state.liberarAcesso;
    liberarAcesso[pTela] = true;
    this.setState({ liberarAcesso: liberarAcesso });
  }


  async listar(pPessoaUsuario) {
    this.setState({ tabelaCarregando: true });
    let pessoaUsuario = this.state.pessoaUsuario;
    if (pPessoaUsuario) {
      pessoaUsuario = pPessoaUsuario;
    }
    const listaPesquisa = [];

    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
    pesquisa.conteudo = this.constante.seqTipoRelacionamentoEmpresa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa_relacionamento.seq_pessoa_relacionada';
    pesquisa.conteudo = pessoaUsuario._seqPessoa;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);


    if (this.state.filtro.inconsistencia) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.seq_pessoa_perfil';
      pesquisa.conteudo = this.state.filtro.inconsistencia;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro.documento) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.documento';
      pesquisa.conteudo = this.state.filtro.documento;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro.nome) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.nome';
      pesquisa.conteudo = this.state.filtro.nome;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'TEXTO';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro.dataCadastroInicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.data_cadastro';
      pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtro.dataCadastroInicial);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_INICIAL';
      listaPesquisa.push(pesquisa);
    }

    if (this.state.filtro.dataCadastroFinal) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.data_cadastro';
      pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtro.dataCadastroFinal);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = 'DATA_FINAL';
      listaPesquisa.push(pesquisa);
    }
    console.log(listaPesquisa)
    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    let listaCartao = retorno.objeto;


    console.log(listaCartao)
    this.setState({
      listaCartao: listaCartao,
      tabelaCarregando: false,
    })

    return listaCartao;
  }


  async listarOperadora() {
    const listaPesquisa = [];

    let pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_perfil';
    pesquisa.conteudo = this.constante.seqPerfilOperadora;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = 'TEXTO';
    listaPesquisa.push(pesquisa);

    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    let listaOperadora = retorno.objeto
    console.log('OPERADORAS', listaOperadora);

    this.setState({
      listaOperadora: listaOperadora,
    })

    return listaOperadora;
  }

  render() {
    return (
      <div>
        <Toast ref={this.toast} />
        {this.state.liberarAcesso.detalheCartao && <Navigate to={"/sistema/empresa/detalhe_cartao?id=" + (this.state.pessoaFuncionario._seqPessoa)} />}
        <Dialog
          header="Cadastro de cartões"
          visible={this.state.vModalNovo}
          style={{ width: 900 }}
          onHide={() => this.resetar()}
        >
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              let inputsOkay = this.validarInputs();
              if (!inputsOkay) {
                return;
              } else {
                this.salvar();
              }
            }}
            style={{
              padding: 5,
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <div
              style={{
                marginTop: 5,
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: 20,
              }}
            >
              <div>
                <label>Nome</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, nome: pTexto.target.value }
                  })}
                  value={this.state.pessoaFuncionario.nome || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.nome && "var(--red-600)" }} />
                {this.state.inputError.nome && (
                  <small style={{ color: "var(--red-600)" }}>
                    Nome inválido
                  </small>
                )}
              </div>

              <div>
                <label>CPF</label><br />
                <InputText
                  type="tel"
                  value={this.state.pessoaFuncionario.documento || ''}
                  onChange={e => {
                    if (e.target.value.length > 14) return;
                    this.setState({
                      pessoaFuncionario: {
                        ...this.state.pessoaFuncionario,
                        documento: this.util.formatarCPF(e.target.value)
                      }
                    })
                  }}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.cpf && "var(--red-600)" }} />
                {this.state.inputError.cpf && (
                  <small style={{ color: "var(--red-600)" }}>
                    Cpf inválido
                  </small>
                )}
              </div>

              <div>
                <label>Matricula</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, tags0: pTexto.target.value }
                  })}
                  value={this.state.pessoaFuncionario.tags0 || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.matricula && "var(--red-600)" }} />
                {this.state.inputError.matricula && (
                  <small style={{ color: "var(--red-600)" }}>
                    Matricula inválido
                  </small>
                )}
              </div>

              <div>
                <label>N° do cartão </label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, tags1: pTexto.target.value }
                  })}
                  value={this.state.pessoaFuncionario.tags1 || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.numeroCartao && "var(--red-600)" }} />
                {this.state.inputError.numeroCartao && (
                  <small style={{ color: "var(--red-600)" }}>
                    Numero cartão inválido
                  </small>
                )}
              </div>

              <div>
                <label>Dias trabalhados</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, tagn0: pTexto.target.value }
                  })}
                  value={this.state.pessoaFuncionario.tagn0 || ''}
                  style={{ width: '100%', height: 40, borderColor: this.state.inputError.diasTrabalhado && "var(--red-600)" }} />
                {this.state.inputError.diasTrabalhado && (
                  <small style={{ color: "var(--red-600)" }}>
                    Dias trabalhados inválido
                  </small>
                )}
              </div>

              <div>
                <label>Valor diário</label><br />
                <InputNumber
                  onChange={pTexto => this.setState({
                    pessoaFuncionario:
                      { ...this.state.pessoaFuncionario, tagn1: pTexto.value }
                  })}
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  value={this.state.pessoaFuncionario.tagn1 || ''}
                  inputStyle={{ width: '100%', height: 40, borderColor: this.state.inputError.valorDiario && "var(--red-600)" }} /><br />
                {this.state.inputError.valorDiario && (
                  <small style={{ color: "var(--red-600)" }}>
                    Valor diário inválido
                  </small>
                )}
              </div>

              <div>
                <label>Operadora</label><br />
                <Dropdown
                  onChange={pItem => this.setState({ pessoaOperadora: pItem.target.value })}
                  value={this.state.pessoaOperadora}
                  options={this.state.listaOperadora}
                  optionLabel='nome'
                  placeholder='Selecione a operadora'
                  filter
                  filterBy="nome"
                  style={{
                    width: "100%",
                    height: 40,
                    borderColor: this.state.inputError.operadora && "var(--red-600)",
                  }}
                />
                {this.state.inputError.operadora && (
                  <small style={{ color: "var(--red-600)" }}>
                    Operadora inválida
                  </small>
                )}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                loading={this.state.salvarCarregando}
                label="Salvar"
                className="p-button-raised"
                type="submit"
              />
            </div>
          </form>
        </Dialog>

        <Dialog
          header="Importar excel"
          visible={this.state.vModaExcel}
          style={{ width: 900 }}
          onHide={() => this.setState({ vModaExcel: false })}>
          <h2 style={{ textAlign: 'center' }}>Em desenvolvimento</h2>
        </Dialog>
        <HeaderEmpresa />
        <main style={{ width: "98%", margin: "20px auto" }}>
          <Messages ref={this.mensagem} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid lightgray",
              padding: 20,
              borderRadius: 3,
            }}
          >
            <h1 style={{ fontSize: 22 }}>Cartões</h1>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20 }}>
              <Button
                label="Importar excel"
                className="p-button-raised"
                onClick={() => this.setState({ vModaExcel: true })} />
              <Button
                label="Novo"
                className="p-button-raised"
                onClick={() => this.setState({ vModalNovo: true })} />
            </div>

          </div>

          <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr ', gap: 20 }}>
              <div>
                <label>Nome</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    filtro: {
                      ...this.state.filtro, nome: pTexto.target.value
                    }
                  })}
                  placeholder="Nome"
                  value={this.state.filtro.nome}
                  style={{ width: '100%', height: 40 }}
                />
              </div>

              <div>
                <label>CPF</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    filtro: {
                      ...this.state.filtro, documento: this.util.formatarCPF(pTexto.target.value)
                    }
                  })}
                  placeholder="000.000.000-00"
                  value={this.state.filtro.documento || ''}
                  style={{ width: '100%', height: 40 }}
                />
              </div>

              <div>
                <label>Data Cadastro Inicial</label><br />
                <Calendar
                  showButtonBar
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                  onChange={(pTexto) => this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroInicial: pTexto.target.value
                    }
                  })}
                  style={{ width: '100%', height: 40 }}
                />
              </div>

              <div>
                <label>Data Cadastro Final</label><br />
                <Calendar
                  showButtonBar
                  dateFormat="dd/mm/yy"
                  placeholder="dd/mm/yyyy"
                  onChange={(pTexto) => this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastroFinal: pTexto.target.value
                    }
                  })}
                  style={{ width: '100%', height: 40 }}
                />
              </div>

              {/* <div>
                <label>Inconsistência</label><br />
                <Dropdown
                  onChange={pItem => this.setState({
                    filtro: {
                      ...this.state.filtro, inconsistencia: pItem.target.value
                    }
                  })}
                  options={[
                    { nome: 'Todos', value: '' },
                    { nome: 'Sim', value: this.constante.seqPerfilFuncionarioPendente },
                    { nome: 'Não', value: this.constante.seqPerfilFuncionarioOK }]}
                  optionLabel='nome'
                  value={this.state.filtro.inconsistencia}
                  placeholder='Selecione'
                  style={{
                    width: "100%",
                    height: 40,
                  }}
                />
              </div> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
              <Button loading={this.state.salvarCarregando} label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gridTemplateColumns: "1fr",
              marginTop: 20,
              gap: 10,
            }}
          >
            <DataTable
              selectionMode="single"
              showGridlines
              removableSort
              paginator
              responsiveLayout="scroll"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
              style={{ width: "100%" }}
              value={this.state.listaCartao}
              onSelectionChange={e => this.selecionar(e.value)}
              loading={this.state.tabelaCarregando}
            >
              <Column header='Matricula' field='tags0' sortable />
              <Column header='Nome' field='nome' sortable />
              <Column header='CPF' field='documento' sortable />
              <Column header='N. Cartão' field='tags1' sortable />
              <Column header='Valor diario' field='tagn1' sortable body={item => <p>{this.util.formatarValor(item.tagn1)}</p>} />
              <Column header='Saldo' field='tagn2' sortable body={item => <p>{this.util.formatarValor(item.tagn2)}</p>} />
              <Column header='Dias trabalhados' field='tagn0' sortable />
              <Column header='Operadora' field='tags2' sortable />
              <Column header='Data cadastro' sortable body={item => <p>{this.util.formatarDataComTimestamp(item.dataCadastro)}</p>} />
              {/* <Column header='Iconsistencia' field='seqPessoaPerfil' sortable body={(pItem) => <p>{pItem.seqPessoaPerfil === this.constante.seqPerfilFuncionarioOK ? 'Não' : 'Sim'}</p>} /> */}
            </DataTable>
          </div>
        </main>
      </div>
    );
  }
}
